import { defineStore } from 'pinia'
import axios from 'axios'

export const useSiteStore = defineStore('site', {
  state: () => ({
    loading: false,
    configData: null,
    landingData: null
  }),
  actions: {
    async fetchConfigData() {
      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/site-configuration`)
        this.configData = response.data.data
      } catch (error) {
        console.error('Error loading config data:', error)
      }
    },
    async fetchLandingData() {
      this.loading = true
      try {
        const response = await axios.get(`${import.meta.env.VITE_APP_API_URL}/landing-page`, {
          params: {
            populate: '*'
          }
        })
        this.landingData = response.data.data
      } catch (error) {
        console.error('Error loading landing data:', error)
      } finally {
        this.loading = false
      }
    }
  }
})
