import { defineStore } from 'pinia'
import axios from 'axios'

export const useReservationStore = defineStore('reservation', {
  state: () => ({
    loading: false
  }),
  actions: {
    async requestReservation(data) {
      this.loading = true
      try {
        await axios.post(`${import.meta.env.VITE_APP_API_URL}/reservations`, {
          data: data,
          headers: {
            'Content-Type': 'application/json'
          }
        })
      } catch (error) {
        console.error('Error requesting reservation:', error)
        throw error
      } finally {
        this.loading = false
      }
    }
  }
})
