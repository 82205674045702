<template>
  <div class="flex justify-center py-4">
    <div class="card w-full max-w-lg bg-secondary shadow-lg" id="reserve">
      <div class="card-body border-2 rounded-lg border-dashed border-neutral">
        <h2 class="card-title text-primary text-xl font-medium"><IconBell /> Reservation</h2>
        <form class="flex flex-col gap-2" @submit.prevent="submitReservation">
          <label class="w-full">
            <div class="label">
              <span class="label-text">Full Name</span>
            </div>
            <input
              type="text"
              class="input input-bordered w-full text-white border-neutral placeholder-neutral"
              placeholder="ex. John Doe"
              v-model="formData.name"
              aria-label="Name"
            />
          </label>
          <div class="flex flex-row gap-2">
            <label class="w-full">
              <div class="label">
                <span class="label-text">Email Address</span>
              </div>
              <input
                type="email"
                class="input input-bordered w-full text-white border-neutral placeholder-neutral"
                placeholder="ex. test@example.com"
                v-model="formData.email"
                aria-label="Email"
              />
              <div class="label" v-if="formData.email && !isEmailValid(formData.email)">
                <span class="label-text text-error" aria-live="polite">Invalid email address</span>
              </div>
            </label>
            <label class="w-full">
              <div class="label">
                <span class="label-text">Phone Number</span>
              </div>
              <input
                type="tel"
                class="input input-bordered w-full text-white border-neutral placeholder-neutral"
                placeholder="ex. +66610205670"
                v-model="formData.phone"
                aria-label="Phone Number"
              />
              <div class="label" v-if="formData.phone && !isPhoneValid(formData.phone)">
                <span class="label-text text-error" aria-live="polite">Invalid phone number</span>
              </div>
            </label>
          </div>
          <div class="flex flex-row gap-2">
            <label class="w-full">
              <div class="label">
                <span class="label-text">Number of Guests</span>
              </div>
              <input
                type="number"
                class="input input-bordered w-full text-white border-neutral placeholder-neutral"
                placeholder="ex. 3"
                v-model="formData.number_of_guests"
                aria-label="Number of Guests"
                min="1"
              />
            </label>
            <label class="w-full">
              <div class="label">
                <span class="label-text">Reservation Date</span>
              </div>
              <input
                type="text"
                class="input pika-single input-bordered w-full text-white border-neutral placeholder-neutral text-primary"
                ref="reservationDateRef"
                placeholder="Pick a date"
                value=""
                id="reservationDatePicker"
              />
            </label>
          </div>
          <label class="w-full">
            <div class="label">
              <span class="label-text">Notes</span>
            </div>
            <textarea
              class="textarea textarea-bordered w-full h-30 text-white placeholder-neutral border-neutral bg-secondary"
              placeholder="Any notes for us? eg. allergies, preferences etc."
              v-model="formData.notes"
              aria-label="Notes"
            ></textarea>
            <div class="label">
              <span class="label-text-alt text-xs"
                >P.S. Please include name, number of guests, contact info (email/phone) and include
                notes for us (if any).</span
              >
            </div>
          </label>
          <div class="card-actions mt-4 flex justify-center">
            <button
              class="btn bg-secondary hover:bg-primary border-2 border-primary hover:border-white disabled:border-neutral text-primary hover:text-white disabled:text-neutral disabled:cursor-not-allowed transition"
              aria-label="Book Now"
              :disabled="!formIsValidated || reservationStore.loading"
              @click="confirmReservation"
            >
              <span class="loading loading-spinner" v-if="reservationStore.loading"></span>
              Book Now
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pika-single {
  background: none;
  color: white;
}
</style>

<script setup>
import { reactive, computed, onMounted, onBeforeUnmount, ref } from 'vue'
import { isEmailValid, isPhoneValid } from '@/utils/validators'
import { useReservationStore } from '@/stores/reservation'
import { toast } from 'vue3-toastify'
import Pikaday from 'pikaday'

import IconBell from '../icons/IconBell.vue'

const reservationStore = useReservationStore()

const reservationDateRef = ref(null)
let reservationDatePicker = null

onMounted(() => {
  reservationDatePicker = new Pikaday({
    field: reservationDateRef.value,
    format: 'YYYY-MM-DD',
    onSelect: (date) => {
      formData.reservation_at = date.toISOString().split('T')[0]
    }
  })
  reservationDatePicker.gotoToday()
})

onBeforeUnmount(() => {
  if (reservationDatePicker) {
    reservationDatePicker.destroy()
  }
})

const blankFormData = {
  name: '',
  email: '',
  phone: '',
  number_of_guests: null,
  notes: '',
  reservation_at: null
}

const formData = reactive({
  ...blankFormData
})

const resetFormData = () => {
  Object.assign(formData, blankFormData)

  if (reservationDatePicker) {
    reservationDatePicker.clear()
  }
}

const formIsValidated = computed(() => {
  if (formData.name === '' || formData.number_of_guests < 1) return false
  if (formData.email === '' && formData.phone === '') return false
  if (formData.email !== '' && !isEmailValid(formData.email)) return false
  if (formData.phone !== '' && !isPhoneValid(formData.phone)) return false
  if (!formData.reservation_at) return false
  return true
})

const confirmReservation = async () => {
  try {
    await reservationStore.requestReservation(formData)
    toast.success('Booking Request Successful')
    resetFormData()
  } catch (e) {
    console.log('error happened : ', e)
    toast.error(e.response?.data?.error.message)
  }
}
</script>
