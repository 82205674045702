<template>
  <section
    class="flex flex-col justify-between items-center w-full px-6 md:px-12 mt-6 lg:mt-12 max-w-auto mx-auto space-y-4"
  >
    <div class="divider lg:divider-start">
      <h2 class="text-lg md:text-2xl font-semibold text-primary flex flex-row items-center gap-3">
        <IconContact />Contact Us
      </h2>
    </div>
    <div
      class="flex flex-col lg:flex-row space-y-8 lg:space-y-0 gap-6 lg:gap-12 items-center w-full"
    >
      <!-- Contact Section -->
      <div class="text-justify lg:text-left lg:w-1/2 max-w-full space-y-3">
        <div class="flex flex-col space-y-2">
          <div class="flex flex-row space-x-2 items-center">
            <IconEmail class="text-primary" />
            <a :href="`mailto:${emailAddress}`" class="text-white text-sm hover:underline">{{
              emailAddress
            }}</a>
          </div>
          <div class="flex flex-row space-x-2 items-center">
            <IconPhone class="text-primary" />
            <a
              :href="whatsAppLink()"
              target="_blank"
              rel="noopener noreferrer"
              class="text-white text-sm hover:underline"
              >{{ phoneNumber }}</a
            >
          </div>
        </div>
        <div class="py-4 flex flex-col gap-6">
          <!-- map embed -->
          <div class="aspect-video w-full rounded-lg overflow-hidden shadow-lg">
            <iframe
              :src="mapEmbedSource"
              class="w-full h-full border-0"
              allowfullscreen=""
              referrerpolicy="no-referrer-when-downgrade"
              loading="lazy"
              title="location"
            ></iframe>
          </div>
          <div class="flex flex-row space-x-2 items-center" v-if="addressText">
            <IconMap class="text-primary" />
            <p class="text-white text-sm whitespace-pre-line">{{ addressText }}</p>
          </div>
        </div>
      </div>
      <!-- Reservation -->
      <div class="lg:w-1/2">
        <!-- Reservation form goes here -->
        <div class="border-2 border-neutral border-dashed" v-if="!reservationIsOpen">
          <h1
            class="text-primary text-lg md:text-4xl font-medium text-start lg:text-left p-8 md:p-12"
          >
            Reservation will start soon! Please Stay Tuned...
          </h1>
        </div>
        <ReservationForm v-else />
      </div>
    </div>
  </section>
</template>

<script setup>
import IconContact from '../icons/IconContact.vue'
import IconEmail from '../icons/IconEmail.vue'
import IconPhone from '../icons/IconPhone.vue'
import IconMap from '../icons/IconMap.vue'
import ReservationForm from '../forms/ReservationForm.vue'

// Define props
const props = defineProps({
  emailAddress: {
    type: String,
    required: true
  },
  phoneNumber: {
    type: String,
    required: true
  },
  mapEmbedSource: {
    type: String,
    required: true
  },
  addressText: {
    type: String,
    default: null
  },
  reservationIsOpen: {
    type: Boolean,
    default: false
  }
})

const whatsAppLink = () => {
  return `https://wa.me/${props.phoneNumber}`
}
</script>
