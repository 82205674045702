<template>
  <section
    class="relative h-screen flex items-center justify-center bg-cover bg-center"
    :style="{ backgroundImage: `url(${BgImage})` }"
  >
    <!-- Overlay -->
    <div class="absolute inset-0 bg-secondary opacity-65"></div>

    <!-- Content -->
    <div
      class="z-10 flex flex-col-reverse lg:flex-row justify-between w-full px-6 md:px-12 items-center max-w-6xl gap-6 lg:gap-0"
    >
      <div class="text-center lg:text-left lg:w-1/2 lg:space-y-8">
        <div class="space-y-3">
          <h1 class="text-xl md:text-2xl font-regular text-primary uppercase">{{ heroText }}</h1>
          <p class="text-lg md:text-xl text-primary font-medium">
            {{ heroSubText.split('.')[0] }}
          </p>
        </div>
        <button
          class="mt-6 px-8 py-2 bg-primary text-white font-semibold text-md rounded-lg shadow-lg hover:bg-primary-focus transition"
          aria-label="reserve-cta"
          @click="scrollToReserve"
        >
          Reserve
        </button>
      </div>
      <div class="lg:w-1/2 flex justify-center">
        <img :src="MonLogo" alt="logo" rel="preload" class="h-32 w-auto md:h-48 lg:h-60" />
      </div>
    </div>
  </section>
</template>

<script setup>
import BgImage from '@/assets/images/bkg_img.jpeg'
import MonLogo from '@/assets/mon_logo.png'

// Define props
defineProps({
  heroText: {
    type: String,
    default: ''
  },
  heroSubText: {
    type: String,
    default: ''
  }
})

// Define emits
const emit = defineEmits(['toReserve'])

const scrollToReserve = () => emit('toReserve')
</script>
