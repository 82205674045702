<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5">
    <g>
      <path
        d="M18.97 22.75H4.96997C1.21997 22.75 1.21997 20.67 1.21997 19V18C1.21997 17.04 2.00997 16.25 2.96997 16.25H20.97C21.93 16.25 22.72 17.04 22.72 18V19C22.72 20.67 22.72 22.75 18.97 22.75ZM2.96997 17.75C2.82997 17.75 2.71997 17.86 2.71997 18V19C2.71997 20.64 2.71997 21.25 4.96997 21.25H18.97C21.22 21.25 21.22 20.64 21.22 19V18C21.22 17.86 21.11 17.75 20.97 17.75H2.96997Z"
        fill="currentColor"
      ></path>
      <path
        d="M20.72 17.75H3.27002C2.86002 17.75 2.52002 17.41 2.52002 17V13C2.52002 8.9 5.43002 5.3 9.43002 4.45C10.02 4.32 10.64 4.25 11.27 4.25H12.72C13.36 4.25 13.98 4.32 14.57 4.45C18.57 5.31 21.47 8.91 21.47 13V17C21.47 17.41 21.14 17.75 20.72 17.75ZM4.02002 16.25H19.97V13C19.97 9.61 17.57 6.63 14.25 5.91C13.76 5.8 13.25 5.75 12.72 5.75H11.27C10.75 5.75 10.24 5.8 9.75002 5.91C6.43002 6.62 4.02002 9.6 4.02002 13V16.25Z"
        fill="currentColor"
      ></path>
      <path
        d="M9.59 5.93C9.26 5.93 8.96 5.71 8.87 5.38C8.79 5.09 8.75 4.8 8.75 4.5C8.75 2.71 10.21 1.25 12 1.25C13.79 1.25 15.25 2.71 15.25 4.5C15.25 4.8 15.21 5.09 15.13 5.38C15.03 5.76 14.65 6 14.25 5.91C13.76 5.8 13.25 5.75 12.72 5.75H11.27C10.75 5.75 10.24 5.8 9.75 5.91C9.7 5.92 9.65 5.93 9.59 5.93ZM11.27 4.25H12.72C13.06 4.25 13.41 4.27 13.74 4.31C13.65 3.43 12.9 2.75 12 2.75C11.1 2.75 10.36 3.43 10.26 4.31C10.6 4.27 10.93 4.25 11.27 4.25Z"
        fill="currentColor"
      ></path>
      <path
        d="M15 11.75H9C8.59 11.75 8.25 11.41 8.25 11C8.25 10.59 8.59 10.25 9 10.25H15C15.41 10.25 15.75 10.59 15.75 11C15.75 11.41 15.41 11.75 15 11.75Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
</template>
