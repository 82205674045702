<template>
  <LoadingScreen v-if="siteStore.loading" />
  <div v-if="siteStore.landingData">
    <HeroSection
      :hero-text="siteStore.landingData?.hero_text"
      :hero-sub-text="siteStore.landingData?.hero_sub_text"
      @to-reserve="scrollToReserve"
    />
    <AboutSection
      :about-title="siteStore.landingData?.about_title"
      :about-text="siteStore.landingData?.about_text"
    />
    <ChefSection
      :chef-title="siteStore.landingData?.chef_title"
      :chef-text="siteStore.landingData?.chef_text"
      :featured-links="siteStore.landingData?.featured_links"
    />
    <MenuSection
      :menu-text-left="siteStore.landingData?.menu_text_left"
      :menu-text-right="siteStore.landingData?.menu_text_right"
    />
    <WineSection :wine-text="siteStore.landingData?.wine_text" />
    <!-- Contact Us  + Reservation -->
    <ContactSection
      :email-address="siteStore.landingData?.contact_email"
      :phone-number="siteStore.landingData?.contact_phone"
      :map-embed-source="siteStore.landingData?.gmap_embed_src"
      :address-text="siteStore.landingData?.contact_address"
      :reservation-is-open="siteStore.configData?.reservation_is_open"
    />
  </div>
</template>

<script setup>
import { onBeforeMount } from 'vue'
import { useHead } from '@vueuse/head'
import { useSiteStore } from '@/stores/site'

import LoadingScreen from '@/components/LoadingScreen.vue'
import HeroSection from '@/components/landing/HeroSection.vue'
import AboutSection from '@/components/landing/AboutSection.vue'
import ChefSection from '@/components/landing/ChefSection.vue'
import MenuSection from '@/components/landing/MenuSection.vue'
import WineSection from '@/components/landing/WineSection.vue'
import ContactSection from '@/components/landing/ContactSection.vue'

const siteStore = useSiteStore()

onBeforeMount(() => {
  siteStore.fetchConfigData()
  siteStore.fetchLandingData()
})

const siteTitle = 'Môn | Progressive Indigenous Cuisine'
const siteDescription =
  'Stories served on a plate. Celebrate the flavors and tales of the hilly regions.'

const siteKeywords = ['Indigenous cuisine', 'borderlands', 'progressive food', 'chef Arpon Changma']
const siteUrl = import.meta.env.VITE_APP_PUBLISHED_URL

useHead({
  title: siteTitle,
  meta: [
    { name: 'description', content: siteDescription },
    {
      name: 'keywords',
      content: siteKeywords.toString()
    },
    { property: 'og:title', content: siteTitle },
    { property: 'og:description', content: siteDescription },
    { property: 'og:image', content: `${siteUrl}/images/mon_logo.png` },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: siteUrl }
  ]
})

const scrollToReserve = () => {
  const section = document.getElementById('reserve')
  section.scrollIntoView({ behavior: 'smooth' })
}
</script>
