import { parsePhoneNumberFromString } from 'libphonenumber-js'
import emailValidator from 'email-validator'

export const isPhoneValid = (phone) => {
  try {
    const phoneNumber = parsePhoneNumberFromString(phone)
    return phoneNumber?.isValid() || false
  } catch (error) {
    return false
  }
}

export const isEmailValid = (email) => {
  return emailValidator.validate(email)
}
